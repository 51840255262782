import React from "react";
import ReadMore from "../Common-Health-Records/ReadMore";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const MemberLabResultsMobile = (props) => {
  const { fields, data } = props;

  return (
    <ul
      className="list-group d-block d-md-none no-list-title"
      id="recordsToPrint"
    >
      {data?.map((item, index) => (
        <li className="list-group-item " key={index}>
          <div className="row">
            <DisplayItem
              nameField={fields.lblDatePerformed1}
              valueField={item.Service_Date}
              outClass={"col-lg-4 col-md-6 details"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
            <DisplayItem
              nameField={fields.lblLabPerformed}
              valueField={item.Description}
              outClass={"col-lg-4 col-md-6 details"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
          </div>

          <div id={"readMoreDetails" + index} className="">
            <ReadMore props={fields}>
              <DisplayItem
                nameField={fields.lblYourResult}
                valueField={item.ResultValue}
                outClass={"col-lg-4 col-md-6 details"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
              <div className="close-btn" id={"closeBtnDetails" + index}></div>
            </ReadMore>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MemberLabResultsMobile;

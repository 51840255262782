import React, { useContext } from "react";
import {
  Text,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { GLOBAL_VARIABLE } from "../../define.constants";
import { getStateConfiguration } from "./../../utils/helpers";
import { AppContext } from "../../contexts/app.context";
import { SC, MEDICAID } from "../../define.constants";
const MyResourcesPregnancyandYourBaby = (props) => {
  let fields = props?.fields;
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  console.log("field-14", props?.fields);
  let { MemberStateName, memberLOB } = useContext(AppContext);
  const list = getStateConfiguration(props.fields, MemberStateName, memberLOB);
  console.log("list-16", list);

  let isSCMedicaidMember = false;
  // MEDICAID Check- SC
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  isSCMedicaidMember =
    isMedicaidMember && MemberStateName?.toUpperCase() === SC;

  return (
    <>
      <br />
      <div className="font-win-comingSoon">
        <RichText field={props?.fields?.description1} />
      </div>
    </>
  );
};

export default MyResourcesPregnancyandYourBaby;

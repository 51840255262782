const constants = {
  apigeeURLs: {
    //Member Section
    userLogin: "/v1/ext/ad/pingone/authsitecore/token",
    //removed "/" in url config. for refreshtoken
    RefreshToken: "/v1/ext/ad/authmobile/refreshtoken",

    // Registration Section
    GetStateListForReg: "/v1/ext/memapi/myaccount/GetStateListForReg",
    ValidateMembership:
      "/v1/ext/memapi/registration/ValidateMembershipRegistration",
    CreateAccount: "/v1/ext/memapi/registration/CreateMemberAccount",
    EmailExistsInSystem:
      "/v1/ext/memapi/registration/EmailExistsForRegistration",
    UserIdExistsInSystem:
      "/v1/ext/memapi/registration/UserIdExistsForRegistration",
    ValidateSecurityCode:
      "/v1/ext/memapi/registration/ValidateSecurityCodeForRegistration",
    SendOrResendSecurityCode:
      "/v1/ext/memapi/registration/SendOrResendSecurityCodeForRegistration",
    ForgotUIDPd: "/v1/ext/memapi/myaccount/ForgotUserIDPassword",
    ForgotUIDPdPost: "/v1/ext/memapi/myaccount/ForgotUserIDPasswordPost",
    ResetPd: "/v1/ext/memapi/myaccount/ResetPassword",
    SendCDPEvent: "/v1/ext/memapi/registration/SendCDPEvent",
    //MemberInfo Section
    MemberInfoAll: "/v1/ext/memapi/memberinfo/MemberInfoAll",
    PCPInformation: "/v1/ext/memapi/memberinfo/PCPInformation",
    GraceDetails: "/v1/ext/memapi/memberinfo/GetGraceDetails",
    SapphireChangePCPSSO: "/v1/ext/memapi/memberinfo/SapphireChangePCPSSO",
    GetCVSCaremarkSSORedirect:
      "/v1/ext/memapi/memberinfo/GetCVSCaremarkSSORedirect",
    GetHPSSSORedirect: "/v1/ext/memapi/memberinfo/GetHPSSSORedirect",
    InstaMedSSORedirect: "/v1/ext/memapi/memberinfo/InstaMedSSORedirect",
    GetCVSAccordantSSORedirect:
      "/v1/ext/memapi/memberinfo/GetCVSAccordantSSORedirect",
    GetAlerts: "/v1/ext/memapi/memberinfo/GetAlerts",
    //Contactus
    CareNetNALContactUs: "/v1/ext/memapi/memberinfo/CareNetNALContactUs",
    SendContactUSMail: "/v1/ext/memapi/memberinfo/SendContactUSMail",

    //AccountSettings
    UpdateLanguage: "/v1/ext/memapi/myaccount/UpdateLanguage",
    ChangePd: "/v1/ext/memapi/myaccount/ChangePassword",
    ViewCPPlanNotification: "/v1/ext/memapi/myaccount/ViewCPPlanNotification",
    UpdateCPPlanNotification:
      "/v1/ext/memapi/myaccount/UpdateCPPlanNotification",
    AccountSettingsProfile:
      "/v1/ext/memapi/myaccount/GetAccountSettingsProfile",

    //AccountSettings --Access Management
    GrantAccess: "/v1/ext/memapi/myaccount/GrantAccess",
    //AccountSettings --Communication Preference
    ViewContactPreferences: "/v1/ext/memapi/myaccount/ViewContactPreferences",
    ViewContactPreferencesList:
      "/v1/ext/memapi/myaccount/ViewContactPreferencesList",
    UpdateContactPreferences:
      "/v1/ext/memapi/myaccount/UpdateContactPreferences",

    ViewCommunicationPreferences:
      "/v1/ext/memapi/myaccount/ViewCommunicationPreferences",
    UpdateCommunicationPreferences:
      "/v1/ext/memapi/myaccount/UpdateCommunicationPreferences",
    ThirdPartyApps: "/v1/ext/memapi/memberinfo/ThirdPartyApps",
    SelectedOptin: "/v1/ext/memapi/memberinfo/SelectedOptin",
    SelectedOptOut: "/v1/ext/memapi/memberinfo/SelectedOptOut",
    RevokeMemberConsentApp: "/v1/ext/memapi/memberinfo/RevokeMemberConsentApp",
    //AccountSettings --MFA Options
    SetDefaultMFADevice: "/v1/ext/memapi/myaccount/SetDefaultMFADevice",
    DeleteUseMFADevice: "/v1/ext/memapi/myaccount/DeleteUseMFADevice",
    ActivateUserMFADevice: "/v1/ext/memapi/myaccount/ActivateUserMFADevice",
    CreateUserMFADevice: "/v1/ext/memapi/myaccount/CreateUserMFADevice",
    GetAllUserDevices: "/v1/ext/memapi/myaccount/GetAllUserDevices",
    ChangeEmailInitiate: "/v1/ext/memapi/myaccount/ChangeEmailInitiate",
    ChangeEmailValidate: "/v1/ext/memapi/myaccount/ChangeEmailValidate",
    UpdatePassword: "/v1/ext/memapi/myaccount/UpdatePassword",
    AuditPingMFA: "/v1/ext/memapi/myaccount/AuditPingMFA",
    DeleteMemberAccount: "/v1/ext/memapi/myaccount/DeleteMemberAccount",
    //MyHealth Section
    AllergiesList: "/v1/ext/memapi/myhealth/GetMemberAllergies",
    ServiceAuthorizationList:
      "/v1/ext/memapi/myhealth/ViewServiceAuthorization",
    ELetterList: "/v1/ext/memapi/Letters/GetMemberLetters",
    ViewInpatientAndEDVisits:
      "/v1/ext/memapi/myhealth/ViewInpatientAndEDVisits",
    GetMedicationsHistory: "/v1/ext/memapi/myhealth/GetMedicationsHistory",
    ViewMedications: "/v1/ext/memapi/myhealth/ViewMedications",
    ViewLabResults: "/v1/ext/memapi/myhealth/ViewLabResults",
    GetMemberLabResults: "/v1/ext/memapi/myhealth/GetMemberLabResult",
    ViewMemberReferrals: "/v1/ext/memapi/myhealth/ViewMemberReferrals",
    MemAPIServiceHistory: "/v1/ext/memapi/myhealth/MemAPIServiceHistory",
    GetClaimEOBDocument: "/v1/ext/memapi/coverage/GetClaimEOBDocument",
    CarePlanAccess: "/v1/ext/memapi/myhealth/CarePlanAccess",
    GetCarePlan: "/v1/ext/memapi/myhealth/GetCarePlan",
    GetServicePlan: "/v1/ext/memapi/myhealth/GetHistoryOfAssessment",
    DownloadServicePlan: "/v1/ext/memapi/myhealth/DownloadServicePlan",
    //Coverage Section
    GetDDLYears: "/v1/ext/memapi/coverage/GetDDLYears",
    GetAccumulator: "/v1/ext/memapi/coverage/GetAccumulator",
    ProcedureCostEstimate: "/v1/ext/memapi/memberinfo/ProcedureCostEstimate",
    //Request Provider Directory
    RequestProviderDirectory:
      "/v1/ext/memapi/memberinfo/RequestProviderDirectoryHardCopy",
    //My Messages
    MemMessageAndViewArchive:
      "/v1/ext/memapi/memberinfo/MemMessageAndViewArchive",
    //ArchiveMemMessages: "/v1/ext/memapi/memberinfo/ArchiveMemMessages",
    ArchiveMemMessages: "/v1/ext/memapi/memberinfo/ArchiveMessages",
    GetAttachmentsList: "/v1/ext/memapi/memberinfo/GetAttachmentsList",
    MemMessageDetail: "/v1/ext/memapi/memberinfo/MemMessageDetail",
    DownloadMessageAttachmentFile:
      "/v1/ext/memapi/memberinfo/DownloadMessageAttachmentFile",
    // GetMessageDetails: "/v1/ext/memapi/memberinfo/GetMessageDetails",
    // CreateMessageMail: "/v1/ext/memapi/memberinfo/CreateNewMessage",
    // ListServices: "/v1/ext/memapi/memberinfo/ListServices",
    // GetMessageHeaders: "/v1/ext/memapi/memberinfo/GetMessageHeaders",
    // ReplyMail: "/v1/ext/memapi/memberinfo/CreateNewConversationEntry",
    //SetAttachment
    GetMessageAndArchive: "/v1/ext/memapi/memberinfo/MemMessageAndViewArchive",
    CreateNewConversation: "/v1/ext/memapi/memberinfo/CreateNewConversation",
    GetCategoriesList: "/v1/ext/memapi/memberinfo/GetCategoriesList",
    GetConversationDetails: "/v1/ext/memapi/memberinfo/GetConversationDetails",
    CreateNewConversationEntry:
      "/v1/ext/memapi/memberinfo/CreateNewConversationEntry",
    GetAttachment: "/v1/ext/memapi/memberinfo/GetAttachment",
    SetAttachment: "/v1/ext/memapi/memberinfo/SetAttachment",
    //MyWellness
    GetAssessmentAndValidationList:
      "/v1/ext/memapi/myhealth/GetAssessmentAndValidationList",
    GetDynamicAssessmentQuestionsAndAnswers:
      "/v1/ext/memapi/myhealth/GetDynamicAssessmentQuestionsAndAnswers",
    SubmitMemberAssessmentAnswersToCCA:
      "/v1/ext/memapi/myhealth/SubmitMemberAssessmentAnswersToCCA",
    GetPDHISSORedirect: "/v1/ext/memapi/memberinfo/GetPDHISSORedirect",
    GetProgressNoteViewReportFromCCA:
      "/v1/ext/memapi/myhealth/GetProgressNoteViewReportFromCCA",
    //ssrs report
    GetSSRSAssessment: "/v1/ext/memapi/myhealth/GetSSRSAssessment",
    SubmitMemberRewards: "/v1/ext/memapi/myaccount/SubmitReward",
    //Member ID Card
    GetIdCardReasonList:
      "/v1/ext/memapi/memberinfo/GetMyMolinaIdCardReasonList",
    MemAPIIdCardDetails: "/v1/ext/memapi/memberinfo/MemAPIIdCardDetails",
    GetProfiles: "/v1/ext/memapi/memberinfo/GetProfiles",
    MyMolinaMemReqIDCard: "/v1/ext/memapi/memberinfo/MyMolinaMemReqIDCard",
    GetMemberIDCardPDF: "/v1/ext/memapi/memberinfo/GetMyMolinaMemberIDCardPDF",
    GetHealthSherpaSSORedirect:
      "/v1/ext/memapi/memberinfo/GetHealthSherpaSSORedirect",
    GetOerBanners: "/v1/ext/memapi/memberinfo/HomePagePopupDetails",
    PayerRequestToSF: "/v1/ext/memapi/memberinfo/SubmitPayerRequestDetails",
    //RXSS SSO
    GetRXSSSavingsSSORedirect:
      "/v1/ext/memapi/memberinfo/GetRXSSSavingsSSORedirect",
    GetMemberCitiesForState: "/v1/ext/memapi/myaccount/GetMemberCitiesForState",
    UpdateAddress: "/v1/ext/memapi/myaccount/UpdateAddress",
    SaveNameChangeRequest: "/v1/ext/memapi/memberinfo/SaveNameChangeRequest",
    AddressAutoComplete: "/v1/ext/memapi/myaccount/AddressAutoComplete",
    GetZipCodeChange: "/v1/ext/memapi/myaccount/GetZipCodeChange",
    GetZipCodeFromAutoSuggestionList:
      "/v1/ext/memapi/myaccount/GetZipCodeFromAutoSuggestionList",
    GetMemberLetterDocument: "/v1/ext/memapi/Letters/DownloadElectronicLetter",
    GetCareTeamContactInfoAndAccess:
      "/v1/ext/memapi/myhealth/CareTeamContactInfoAndAccess",
    SubmitCareCoordinationRequestToCCA:
      "/v1/ext/memapi/myhealth/SubmitRequestCareCoordinationToCCA",
    GetCareTeamContactAccessManagement:
      "/v1/ext/memapi/myhealth/CareTeamContactAccessManagement",
    MemberConsentGetEntityNameList:
      "/v1/ext/memapi/myhealth/MemberConsentGetEntityNameList",
    MemberConsentSaveGridData:
      "/v1/ext/memapi/myhealth/MemberConsentSaveGridData",
    //Live Chat
    LiveChatAuditing: "/v1/ext/memapi/memberinfo/LiveChatAuditing",
    ViewVaccination: "/v1/ext/memapi/myhealth/ViewVaccination",
    DeleteSessionInfo: "/v1/ext/memapi/memberinfo/DeleteSessionInfo",
  },
};

const serverConstants = {
  apigeePrefix: "/apigee",
  userLogin: constants.apigeeURLs.userLogin,
  get loginService() {
    if (constants.apigeeURLs.userLogin.startsWith(this.apigeePrefix))
      return constants.apigeeURLs.userLogin;
    return this.apigeePrefix + constants.apigeeURLs.userLogin;
  },
  get memberInfoService() {
    if (constants.apigeeURLs.MemberInfoAll.startsWith(this.apigeePrefix))
      return constants.apigeeURLs.MemberInfoAll;
    return this.apigeePrefix + constants.apigeeURLs.MemberInfoAll;
  },
  get RefreshTokenService() {
    return constants.apigeeURLs.RefreshToken;
  },

  loginPage: "/",
  unsecuredPages: [
    "/",
    "/MemberRegistration",
    "/AccountHelp",
    "/mymolinalogin",
  ],
  userLogout: "/user/logout",
  loginEnv: "/login/env",
  widgetEnv: "/widget/env",
  CDPConfig: "/cdp/config",
  TokenExpirationLimit: 3600000,
  TokenRefreshSpan: 120000,
  deleteCachedApis: [
    {
      updateAPI: constants.apigeeURLs.GrantAccess,
      deleteCacheAPI: constants.apigeeURLs.GetProfiles,
    },
    {
      updateAPI: constants.apigeeURLs.UpdateLanguage,
      deleteCacheAPI: constants.apigeeURLs.AccountSettingsProfile,
    },
    // {
    //   updateAPI: constants.apigeeURLs.UpdateCommunicationPreferences,
    //   deleteCacheAPI: constants.apigeeURLs.ViewCommunicationPreferences,
    // },
    {
      updateAPI: constants.apigeeURLs.SubmitMemberAssessmentAnswersToCCA,
      deleteCacheAPI: constants.apigeeURLs.GetAssessmentAndValidationList,
    },
    {
      updateAPI: constants.apigeeURLs.PayerRequestToSF,
      deleteCacheAPI: constants.apigeeURLs.PayerRequestToSF,
    },
    {
      updateAPI: constants.apigeeURLs.UpdateAddress,
      deleteCacheAPI: constants.apigeeURLs.AccountSettingsProfile,
    },
    {
      updateAPI: constants.apigeeURLs.SaveNameChangeRequest,
      deleteCacheAPI: constants.apigeeURLs.AccountSettingsProfile,
    },
  ],
  cachedApis: [
    constants.apigeeURLs.GetAccumulator,
    constants.apigeeURLs.GraceDetails,
    //constants.apigeeURLs.MemMessageDetail,
    //constants.apigeeURLs.GetCareTeamContactInfoAndAccess,
    constants.apigeeURLs.MemAPIServiceHistory, //paginationAPI used
    constants.apigeeURLs.ServiceAuthorizationList, //paginationAPI used
    constants.apigeeURLs.ViewLabResults, //paginationAPI used
    constants.apigeeURLs.AllergiesList, //paginationAPI used
    constants.apigeeURLs.ViewMedications, //paginationAPI used
    constants.apigeeURLs.ViewInpatientAndEDVisits, //paginationAPI used
    constants.apigeeURLs.ChangePd,
    constants.apigeeURLs.GetAlerts,
    constants.apigeeURLs.GetPDHISSORedirect,
    constants.apigeeURLs.GetDDLYears,
    constants.apigeeURLs.ProcedureCostEstimate,
    constants.apigeeURLs.GetIdCardReasonList,
    constants.apigeeURLs.MemAPIIdCardDetails,

    constants.apigeeURLs.GetMemberIDCardPDF,
    constants.apigeeURLs.GetProfiles,
    //constants.apigeeURLs.ViewCommunicationPreferences,
    constants.apigeeURLs.GetAssessmentAndValidationList,
    constants.apigeeURLs.AccountSettingsProfile,
    constants.apigeeURLs.GetOerBanners,
    constants.apigeeURLs.SaveNameChangeRequest,
    constants.apigeeURLs.GetStateListForReg,
  ],
  ErrorPage: "/ErrorPage",
};

const getURLExports = (isProxy, apigeeHost) => {
  const urlPrefix = isProxy
    ? serverConstants.apigeePrefix
    : apigeeHost
    ? apigeeHost.replace(/\/$/, "")
    : "";
  for (let key in constants.apigeeURLs) {
    constants.apigeeURLs[key] = urlPrefix + constants.apigeeURLs[key];
  }
  return constants;
};

const dynamicControlConstants = {
  textbox: "textbox",
  textarea: "textarea",
  date: "date",
  radio: "radio",
  checkbox: "checkbox",
  select: "select",
  hidden: "hidden",
  scoreid: "scoreid",
};
const assessmentStatus = {
  inProgress: "inprogress",
  notTaken: "not taken",
  completed: "completed",
  neverTaken: "never taken",
};

const assessmentConstants = {
  save: "save",
  submit: "submit",
  none: "none",
};

const manageCommunicationValues = {
  optIn: "Opt-in",
  optOut: "Opt-out",
  none: "none",
};

module.exports = {
  getURLExports,
  serverConstants,
  dynamicControlConstants,
  assessmentStatus,
  assessmentConstants,
  manageCommunicationValues,
};

/**
 * Define global constants here if required
 */

export const ENGLISH_LANG_CODE = "en";
export const SPANISH_LANG_CODE = "es";
export const SPANISH_LANG_TEXT = "spanish";
export const ENGLISH_LANG_TEXT = "english";
export const SPANISH_LANG_PATH = "/es";
export const ENGLISH_LANG_PATH = "/en";
export const HomePageUrl = "/home";
export const LoginPageUrl = "/";
export const MyMolinaLoginUrl = "/mymolinalogin";
export const MyMessagesUrl = "/MyMessages";
export const CreateNewMessageUrl = "/CreateNewMessage";
export const CaseManagertten = "Waiting for a response";
export const CaseManagerttes = "Esperando una respuesta";
export const Membertten = "Waiting for member to respond";
export const Memberttes = "Esperando a que el miembro responda";
export const CarePlan_Name = "CAREPLAN117";
export const SPL_CarePlan_Name = "SPECIALIZED";
export const SOURCE_PORTAL = "Portal";
export const REGISTARTION_URL = "/MemberRegistration";
export const MemberConsent_Hide = false;

export const PWD_VALIDATION_REGEX1 = /^[a-zA-Z0-9_\.]*$/; //--> Password Validation Regex
export const PWD_UPPERCASE_REGEX = /([A-Z])/;
export const PWD_LOWERCASE_REGEX = /([a-z])/;
export const PWD_NUMBER_REGEX = /(\d)/;
export const PWD_VALIDATION_REGEX2 =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{7,64}$/;
export const REPEAT_THREE_REGEX = /(.)\1\1/;
export const PWD_MINI_LENGTH = 8; //--> Password Minimum Length
export const PWD_MINIMUM_LENGTH = 10; //--> Password Minimum Length for Registration
export const PWD_MAX_LENGTH = 20; //--> Password Maximum Length
export const USERNAME_CHECK_LENGTH = 8; // length of Username protion that cannot match password

export const MEDIA_PATH = process.env.PUBLIC_URL || "/dist/MemberPortal";
export const IMAGE_PATH = `${MEDIA_PATH}/img`; //--> /data/media/img
export const ICON_PATH = `${IMAGE_PATH}/icons`; //--> /data/media/img/icons

export const DEFAULT_STATE = "Global";
export const DEFAULT_LOB = "Common";
export const SCREEN_LARGE_START = 1194;
export const PER_PAGE_MESSAGES = 10;

export const DELIMITER_COLON = ":";
export const DELIMITER_VERTICALBAR = "|";
export const DELIMITER_SPACE = " ";
export const DELIMITER_HYPHEN = "-";
export const OH_STANDERD = "";
export const Currency_Symbol = "$";
export const COST_ZERO = "$0.00";
export const DEPENDANTSTATUS = "a";
export const PS_FLAG_STATUS_ACTIVE = "Y";
export const SUCCESS_RESPONSE = "Success";
export const FAILURE_RESPONSE = "Failure";
export const SUCCESS_RESPONSE_CODE = "200";
export const SUCCESS_RESPONSE_MESSAGE = "Updated successfully";
export const ADD_MFA_PING_WIDGET = "ADD_MFA_PING_WIDGET";
export const UPDATE_PASSWORD_AUTH_PING_WIDGET =
  "UPDATE_PASSWORD_AUTH_PING_WIDGET";
export const SUCCESS_RESULT_CODE = 2001;
export const CODE_RESULT_SUCCESS = 200;
export const MFA_SENT_SUCCESS_CODE = 201;
export const DELETE_SUCCESS_RESULT_CODE = 204;
export const INVALID_PASSWORD_CODE = 2067;
export const VALIDATION_MEMBERSHIP_ATTEMPT = 2300;
export const VALIDATE_MEMBERSHIP_ATTEMPT_ONE = 2062;
export const VALIDATE_MEMBERSHIP_ATTEMPT_NEW = 2063;
export const VALIDATE_MEMBERSHIP_ATTEMPT_FIRST = 2304;
export const VALIDATE_MEMBERSHIP_ATTEMPT_SECOND = 2305;
export const VALIDATE_MEMBERSHIP_ATTEMPT_THIRD = 2306;
export const VALIDATE_MEMBERSHIP_ATTEMPT_FORTH = 2307;
export const REGISTRATION_LOGIN_RESTRICTION = 2154;
export const PHONE_FIRST_SECOND_INCORRECT_ATTEMPT = 2312;
export const PHONE_THIRD_INCORRECT_ATTEMPT_COUNT = 2313;
export const PHONE_OVERLAY_SECURITY_CODE_EXPIRED = 2314;
export const SUCCESS_MESS_SENT_RESULT_CODE = 2102;
export const POA_PR_RESULT_CODE = 2510;
export const ACCESS_REVOKE_CODE = 2087;
export const CONTRACT_NAME = "Molina Health California";
export const YES_VALUE = "Yes";
export const NO_VALUE = "No";
export const MEMBER_ID_NO = "memberidno";
export const SSN_NO = "ssn";
export const MEDICARE_CARD_NO = "MEDICARE_CARD_NO";
export const FLAG_YES = "Y";
export const FLAG_NO = "N";
export const ADD = "ADD";
export const UPDATE = "UPDATE";
export const ENTITYID_TX = "34";
export const ENTITYNAME_TX = "PCP_CPC";
export const ENTITYTYPE_TX = "HIPPA Covered";
export const IDCARD_PLANYEAR = 2021;
export const PINGUSERID_NOT_FOUND_RESULT_CODE = 2317;
export const INVALID_PING_PASSWORD_CODE = 2067;
export const ACTIVATION_REQUIRED = "ACTIVATION_REQUIRED";
export const BAD_REQUEST_CODE = 400;
export const FAILURE_RESULT_CODE = 5007;
export const USERID_ALREADY_EXISTS_RESPONSE =
  "Member UserId is existed in system";
export const EMAIL_ALREADY_EXISTS_RESPONSE =
  "Member email is existed in system";

export const MARKETPLACE = "marketplace";
export const MEDICAID = "medicaid";
export const MEDICARE = "medicare";
export const DUALS = "dual";
export const NV = "NV";
export const KY = "KY";
export const NY = "NY";
export const CA = "CA";
export const WA = "WA";
export const NM = "NM";
export const MS = "MS";
export const SC = "SC";
export const FL = "FL";
export const MI = "MI";
export const OH = "OH";
export const TX = "TX";
export const UT = "UT";
// export const IN = "IN";
// export const NE = "NE";
export const WI = "WI";
export const IL = "IL";
export const IA = "IA";
export const NE = "NE";
export const ID = "ID";
export const VA = "VA";
export const MA = "MA";
export const year_2023 = 2023;
export const CY2024_IMP_YEAR = "2024";
export const label2024 = "2024";
export const label2023 = "2023";
export const MEDICARE_CY2024_STATES = [
  "az",
  "ut",
  "wa",
  "ny",
  "nv",
  "ky",
  "ca",
  "id",
  "wi",
  "il",
  "mi",
  "oh",
  "sc",
  "tx",
  "va",
  "ma",
];
export const MEDICARE_CY2024_MAC_STATES = [
  "az",
  "ut",
  "wa",
  "ny",
  "nv",
  "ky",
  "ca",
  "id",
  "wi",
  "mi",
  "oh",
  "sc",
  "tx",
  "va",
  "ma",
  "fl",
];
export const DUALS_CY2024_STATES = ["il", "mi", "oh", "sc", "tx"];
export const DEPENDENT = "dependent";
export const SUBSCRIBER = "subscriber";
export const SELF = "self";
export const DEPENDENT_WITH_ACCESS = "dependent_with_access";
export const INBOX = "viewInbox";
export const ARCHIVE = "viewArchive";
export const ALERT_CATEGORY_HEDIS = "hedis";
export const TTY = "TTY";

export const CHANNEL_PREFERENCE_SMS = "CHANNEL_PREFERENCE_SMS";
export const CHANNEL_PREFERENCE_PHONE = "CHANNEL_PREFERENCE_PHONE";
export const CHANNEL_PREFERENCE_EMAIL = "CHANNEL_PREFERENCE_EMAIL";
export const CHANNEL_ID_SMS = "SMS";
export const CHANNEL_ID_PHONE = "Phone";
export const CHANNEL_ID_EMAIL = "Email";
export const CHANNEL_ID_TOTP = "TOTP";
export const CHANNEL_ID_FIDO = "FIDO2";
export const TOTP_LABEL = "AUTHENTICATOR";
export const FIDO2_LABEL = "BIOMETRICS";
export const PHONE_VALID_REGEX =
  /^\(?([2-9]{1}[0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})|[2-9]{1}[0-9]{9}$/g;
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
export const FIRST_NAME_REGEX = /^[a-zA-Z0-9 ]*$/;
export const LAST_NAME_REGEX = /^[a-zA-Z0-9 ]*$/;
export const MEMBER_REWARDS_MemberID_REGEX = /^[a-zA-Z0-9]*$/;
export const EMAIL_REGEX_CONTACT =
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g;
export const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/;
export const HIDE_EMAIL_CHARACTERS = "X";
export const PAGE_ID = "0";
export const VISIBLE_OPTION = "N";

export const PRIMARY_SUBSCRIBER_ROLEID = "78";
export const DEPENDANT_ACCESS_GRANTED_ROLEID = "79";
export const DEPENDANT_NO_ACCESS_ROLEID = "80";
export const DEPENDANT_18AndOVER = "18AndOver";
export const DEPENDANT_UNDER18 = "Under18";

export const NUMBER_OF_RECORDS_PER_API_CALL = 200;
export const VALID_SECURITY_CODE = "VALID SECURITY CODE";
export const CHANGE_PWD = "ChangePassword";

export const PHONE_CARD_PATH = "PhonePreference.preferences";
export const PHONENUMBER_FIELD = "phoneNumber";
export const PHONETYPE_FIELD = "phoneType";
export const EMAIL_CARD_PATH = "EmailPreference.preferences";
export const EMAILADDRESS_FIELD = "EmailAddress";
export const EMAILTYPE_FIELD = "EmailAddressType";
export const PREFERREDEMAIL_FIELD = "preferredEmail";
export const PREFERREDPHONE_FIELD = "preferredPhone";

export const GLOBAL_VARIABLE = {};

export const ACCESS_MGNT_TAB = "AccountSettings-AccessManagement";
export const MANAGE_COMUNICATION_TAB = "AccountSettings-ManageCommunications";
export const MY_SUBSCRIPTION_TAB = "AccountSettings-MySubscription";
export const MFA_TAB = "AccountSettings-MFA";
export const MYSUBSCRIPTIONDATE = "11/13/2023";
export const CARE_PLAN_TAB = "MyHealthRecord-CarePlan";
export const SERVICE_PLAN_TAB = "MyHealthRecord-ServicePlan";
export const ALLERGIES_TAB = "MyHealthRecord-Allergies";
export const MEDICATIONS_TAB = "MyHealthRecord-Medications";
export const SAPPHIRE_SSO = "SapphireSSO";
export const CAREMARK_SSO = "CaremarkSSO";
export const OTHER_TEXT = "Other";
export const ALERTS_MAX_CHAR = 7;

export const AIAN = "AI-AN";
export const ASSESSMENT_STATUS_COMPLETED = "completed";
export const ASSESSMENT_FLAG_INTERNAL = "INTERNAL";
export const ASSESSMENT_FLAG_EXTERNAL = "EXTERNAL";

export const DISPLAY_POSITION = "3";

export const OPTIN_FlAG_Y = "Y";
export const OPTIN_FlAG_N = "N";
export const GENERIC_ERROR = "GenericError";
export const UNAUTHORIZED = "Unauthorized";
export const WHO_CAN_VIEW = "WhoCanView";
export const UNDER_MAINTENANCE = "UnderMaintenance";
export const ACTION_UNAVAILABLE = "ActionUnavailable";
export const IDLE_ERROR = "IdleError";
export const IDLE_TIMEOUT = "IdleTimeout";
export const SESSION_EXPIRE = "SessionExpire";
export const POPUP_TIMEOUT = "PopupTimeout";
export const EXISTING_EMAIL = "EMAIL_IS_NOT_UNIQUE";
export const INVALID_OPT = "OTP_INVALID";

export const IDLE_POPUP_BUTTON = "IdlePopupButton";

export const TIMEOUT_VALUE_PORTAL = "1200000";
export const TIMEOUT_VALUE_POPUP = "300000";

export const GOT_QUESTIONS = "Contact-GotQuestions";
export const SEND_A_MESSAGE = "Contact-SendaMessage";

export const GRID_1COL_CONTAINER = "grid-1col-container";
export const GRID_2COL_CONTAINER = "grid-2col-container";
export const GRID_3COL_CONTAINER = "grid-3col-container";
export const GRID_4COL_CONTAINER = "grid-4col-container";

export const GENERALQUESTIONS = "a_grnlQns";
export const NURSEADVICELINE = "a_nurseAdvice";
export const NAL_CATEGORY_EN = "NAL (Nurse Advice Line)";
export const NAL_CATEGORY_ES = "NAL (Línea de asesoramiento de enfermería)";

export const ACCUM_EXCLUDE_YEARS = [2022];
export const DATE_MM_DD_YYYY = "mm/dd/yyyy";
export const IUD_FLAG_D = "D";
export const PHONE_DIGIT_ONLY_REGEX = /[^\d]/g;
export const SESSION_COOKIE_TIMEOUT =
  process.env.SESSION_COOKIE_TIMEOUT || 1800; // 30 Mins
export const INVALID_FORMAT_MESSAGE = "Invalid Date Format";
export const INVALID_MIN_MESSAGE = "Date should not be before minimal date";
export const INVALID_MAX_MESSAGE = "Date should not be after maximal date";
export const SCRIPT_REGEX =
  "/<script\b[^<]*(?:(?!</script>)<[^<]*)*</script>/gi";
export const LEGACY_PORTAL_URL =
  process.env.LEGACY_PORTAL_URL || "https://member.molinahealthcare.com";
export const LEGACY_PORTAL_MOBILE_URL =
  process.env.LEGACY_PORTAL_MOBILE_URL ||
  "https://m.member.molinahealthcare.com";
export const ADMIN_PORTAL_URL =
  process.env.ADMIN_PORTAL_URL || "https://admin.molinahealthcare.com";
export const OIDC_AUTH_BASEURL =
  process.env.OIDC_AUTH_BASEURL || "https://login.molinahealthcare.com/as";
export const OIDC_CLIENT_ID =
  process.env.OIDC_CLIENT_ID || "0086fa05-63c7-43ce-99fc-8ca169f52d4d";
export const EXPIRY_DATE_FOR_NM_STATE_POPUP = "February 28, 2022 23:59:59";
export const ASSESSMENTS_ID = "888";
export const ASSESSMENT_ID_680 = "680";
export const ASSESSMENT_ID_732 = "732";
export const ASSESSMENT_ID_738 = "738";
export const ASSESSMENT_ID_740 = "740";
export const ASSESSMENT_ID_1437 = "1437";
export const SUPPORT_USER = "supportUser";
export const TERMED_MEMBER = "termedMember";
export const SWITCHED_TO_DEPENDENT = "switchedToDependent";
export const MULTI_LANG_LINK = "multiLangLink";
export const NON_DISCRIMINATION_LINK = "nonDiscriminationLink";
export const MULTI_LANG_TEXT = "multiLangLink";
export const NON_DISCRIMINATION_TEXT = "nonDiscriminationLink";

export const TABNAME_HEALTHRECORD_CLAIMHISTORY_EN = "claims history";
export const TABNAME_HEALTHRECORD_CLAIMHISTORY_ES =
  "historial de reclamaciones";
export const TABNAME_HEALTHRECORD_SERVICEAUTH = "service authorizations";
export const LAB_RESULTS_TAB = "MyHealthRecord-LabResults";
export const VACCINATIONS_TAB = "MyHealthRecord-Vaccinations";
export const CTCONTACTINFOACCESS_TAB = "MyHealthRecord-CTContactInfoAccess";
export const TABNAME_HEALTHRECORD_MEDICATIONS_EN = "medications";
export const TABNAME_HEALTHRECORD_MEDICATIONS_ES = "medicamentos";

export const GENESYS_USERTYPE = "Member";

/**
 * Start TX Medicaid
 */
export const H1700_1_INDIVIDUAL_SERVICE_PLAN = "H1700-1";
export const H1700_2_INDIVIDUAL_SERVICE_PLAN_ADDENDUM = "H1700-2";
export const H1700_3_INDIVIDUAL_SERVICE_PLAN_SIGNATUREPAGE = "H1700-3";
export const H2060_B_ASSESSMENT = "H2060-B, Needs Assessment Addendum";
export const SERVICE_PLAN = "TxServicePlan";
export const EHRA = "eHRA-TX";
export const TLTSS = "TX Facility Nursing Assessment";
export const LTSS = "LTSS Facility CNA";
export const Export_PDF = "PDF";
export const SSRS_PDF = "viewreport_ssrs_pdf";
/** End TX Medicaid  */

export const GENESYS_MEDICAID = "CS";
export const GENESYS_MEDICARE = "MC";
export const GENESYS_MARKETPLACE = "MP";

export const PLANTOGGLE_MEDICAID = "Medicaid";
export const PLANTOGGLE_MEDICARE = "Medicare";

/**
 * SSO redirects!
 */
export const HEALTH_SHERPA_SSO = "GetHealthSherpaSSORedirect";
export const POD_SAPPHIRE_SSO = "SapphireChangePCPSSO";
export const CVS_CAREMARK_SSO = "GetCVSCaremarkSSORedirect";
export const HPS_SSO = "GetHPSSSORedirect";
export const CVS_ACCORDANT_SSO = "GetCVSAccordantSSORedirect";
export const PDHI_SSO = "GetPDHISSORedirect";
export const OER = "OER";
export const PDHI = "PDHI";
export const PRERENEWAL = "PRERENEWAL";
export const REDETERMINATION = "REDETERMINATION";
export const TERMSCONDITION = "TERMSCONDITION";
export const MFA_NOTIFICATION = "MFA_NOTIFICATION";
export const TEXTMESSAGECONSENT = "TEXTMESSAGECONSENT";
export const ASSESSMENT = "ASSESSMENT";
export const ELETTER_NOTIFICATION = "ELETTER NOTIFICATION";
export const INSTAMED_SSO = "InstaMedSSORedirect";
export const MYSUBSCRIPTION = "MYSUBSCRIPTION";
export const MI_DUAL = "QMXHPQ2246";
export const KYMC_PROGRAMID = "QMXHPQ2305";
export const KYMAPD_PROGRAMID = "QMXHPQ2315";
export const WAFCPRGMID = "MHIHP00008";
export const NYMCC_PROGRAMID = "PGM0000000027";
export const AZ = "AZ";
export const AZMCC_PROGRAMID = "PGM0000000033";
export const DUAL = "D";
export const MCC_Member = "Y";
export const MS_CHIP_PROGRAMID = "QMXHPQ2289";
export const HEALTH_PLAN_RENEWALS = "HEALTH PLAN RENEWALS";
export const MCDNSP_PROGRAMID = "QMXHPQ1683";
export const MCCare_PROGRAMID = "QMXHPQ1682";
export const MCDNSP_PlanID = "QMXBP8279";
export const MC63_PROGRAMID = "QMXHPQ2263";
export const MC004_PROGRAMID = "TFZ22HP00000004";
export const OH_MD_OHIORISE_PLANID1 = "QMXBP8399";
export const OH_MD_OHIORISE_PLANID2 = "QMXBP8400";
export const OH_MD_OHIORISE_PLANID3 = "QMXBP8407";
export const OH_MD_CSP_FULL = "CSP_Full";
export const OH_MD_CSP_PARTIAL = "CSP_Partial";
export const NY_MD_NMC_PROGRAMID = "QMT2HPQ0155";
export const NY_MD_HARP_PROGRAMID = "QMT2HPQ0166";
export const NY_MD_CHP_PROGRAMID = "PGM0000000015";
export const NY_MD_EP_PROGRAMID1 = "QMXHPQ2283";
export const NY_MD_EP_PROGRAMID2 = "QMXHPQ2284";
export const NY_MD_EP_PROGRAMID3 = "QMXHPQ2285";
export const NY_MD_EP_PROGRAMID4 = "QMXHPQ2286";
export const NY_MD_EP_PROGRAMID_EP_200_250 = "QMXREG010003";
export const NY_MD_SWHFIDE_PROGRAMID = "QMXHPQ2308";
export const NY_MC_SWH_PROGRAMID = "QMXHPQ2309";
export const FL_SP_PROGRAMID = "PGM0000000040";
export const FL_MMA_LTC_PROGRAMID = "QMXHPQ2244";
export const FL_MMA_PROGRAMID = "QMXHPQ1268";
export const FL_LTC_PROGRAMID = "QMXHPQ2224";
export const MA_MD_SCO_PROGRAMID = "QMXHPQ2312";
export const MA_MD_SWH_PROGRAMID = "QMXHPQ2310";
export const MA_MD_FIDE_PROGRAMID = "QMXHPQ2307";
export const NY_SWHNY_FIDE_PROGRAMID = " QMXHPQ2308";
export const TX_MEDICAID_STAR = "QMXHPQ0879";
export const TX_MEDICAID_STAR_PLUS = "QMXHPQ0882";
export const TX_MEDICAID_CHIP = "QMXHPQ0881";
export const TX_MEDICAID_CHIP_RSA = "TQPROG1814";
export const TX_MEDICAID_CHIP_PERINATE = "QMXHPQ2141";
export const TX_MEDICAID_CHIP_PERINATE_RSA = "TQPROG1815";
export const MS_MEDICAID_CHIP = "QMXHPQ2289";
export const MS_MEDICAID_CAN = "QMXHPQ2287";
export const IL_HC_PROGRAM = "QMXHPQ0855";
export const IL_MLTSS_PROGRAM = "QMXHPQ0851";
export const MI_MEDICAID_PLAN_HMP = "QMXBP8055";
export const WA_MD_IMCAH = "QMXHPQ2269";
export const WA_MD_IMCAHA = "QMXHPQ2270";
export const WA_MD_IMCBD = "QMXHPQ2271";
export const WA_MD_IMCPREM = "QMXHPQ2272";
export const WA_MD_BHSO = "QMXHPQ2273";
export const WA_MD_APPLE = "QMXREG030001";
export const WA_MD_BHSO_PLANID = "QMXBP8237";
export const WA_MD_PROGID = "QMXHPQ2319";
export const TX_MD_STAR_PROGRAMID = "QMXHPQ0879";
export const TX_MD_PLUS_PROGRAMID = "QMXHPQ0882";
export const TX_MD_STARDUAL_PROGRAMID = "QMXHPQ2249";
export const TX_MD_CHIP_PROGRAMID = "QMXHPQ0881";
export const TX_MD_CHIP_RSA_PROGRAMID = "TQPROG1814";
export const TX_MD_CHIP_PERINATE_RSA_PROGRAMID = "TQPROG1815";
export const TX_MD_CHIPPerinate_PROGRAMID = "QMXHPQ2141";
export const SC_MEDICAID = "PGM0000000015";
export const SC_DUAL_MCAID = "QMXHPQ0852";
export const NM_MCAID = "QMXHPQ1110";
export const SC_DUAL_MCARE = "QMXHPQ0854";
export const VA_MEDICAID_ProgramID = "QMXHPQ2316";
export const VA_CCC_MEDICAID_PlanIDS = ["QMXBP8462", "QMXBP8463"];
//WI BADGERCAREPLUS BANNER
export const WI_MEDICAID_PROGRAMID = "QMXREG050005";
export const WI_MEDICAID_BADGERCARE = "BADGERCAREPLUS";

export const SWH_PROGRAMS = [
  "QMXHPQ2307",
  "QMXHPQ2310",
  "QMXHPQ2308",
  "QMXHPQ2309",
  "QMXHPQ2314",
  "QMXHPQ2312",
];
//NM  Implementation
export const NM_MEDICAID_ProgramID = "QMXREG020003";

export const WA_MD_IMCCommon_LIST = [
  WA_MD_IMCAH,
  WA_MD_IMCAHA,
  WA_MD_IMCBD,
  WA_MD_IMCPREM,
  WA_MD_PROGID,
  WA_MD_APPLE,
];
export const NY_MD_HarpAndMMC_LIST = [
  NY_MD_HARP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
];
export const NY_MD_EP_PROGRAMID_LIST = [
  NY_MD_EP_PROGRAMID1,
  NY_MD_EP_PROGRAMID2,
  NY_MD_EP_PROGRAMID3,
  NY_MD_EP_PROGRAMID4,
  NY_MD_EP_PROGRAMID_EP_200_250,
];

export const IL_MD_HBI_PROGRAMID_LIST = ["QMXHPQ0855", "PGM0000000015"];

export const LA_PLAN_ID = [
  "TFU223BP0000007",
  "TFU223BP0000008",
  "TFU223BP0000009",
  "TFU223BP0000010",
  "TFU223BP0000012",
];
export const COMMON = "COMMON";
export const ZERO = "0";
export const PLAN_MEDICARE_CHOICECARE = "QMXBP8373";

export const NM_MEDICARE_IMAGE = "/id/NonMP/NM/Member_ID_NM_Medicare.png";
export const AZ_MEDICAID_IMAGE = "/id/NonMP/AZ/Medicaid/AZ-Medicaid.png";
export const KY_MEDICAID_IMAGE = "/id/NonMP/KY/Medicaid/Home-KY-Medicaid.png";
export const NV_MEDICAID_IMAGE = "/id/NonMP/NV/Medicaid/Home-NV-Medicaid.png";
export const NY_MEDICAID_MCC_AFFINITY_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-Medicaid-MMC.png";
export const NY_MEDICAID_MCC_NONAFFINITY_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-Medicaid-MMC-NonAffinity.jpg";
export const NY_MEDICAID_HARP_AFFINITY_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-Medicaid-HARP.png";
export const NY_MEDICAID_HARP_NONAFFINITY_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-Medicaid-HARP-NonAffinity.jpg";
export const NY_MEDICAID_COMMON_ESSENTIAL_AFFINITY_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-Affinity-Medicaid-EP.png";
export const NY_MEDICAID_COMMON_ESSENTIAL_NONAFFINITY_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-NonAffinity-Medicaid-EP.png";
export const NY_MEDICAID_CHIP_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-Medicaid-CHIP.png";
export const NY_MEDICAID_MLTC_IMAGE =
  "/id/NonMP/NY/Medicaid/Home-NY-Medicaid-MLTC.png";
export const UT_MEDICAID_IMAGE = "/id/NonMP/UT/Medicaid/Home-UT-Medicaid.png";
export const WI_MEDICAID_IMAGE = "/id/NonMP/WI/Medicaid/Home-WI-Medicaid.png";
export const AZ_MEDICARE_IMAGE = "/id/NonMP/AZ/Medicare/AZ-Medicare.png";
export const KY_MEDICARE_DSNP_IMAGE =
  "/id/NonMP/KY/Medicare/Home-KY-Medicare-DSNP.png";
export const KY_MEDICARE_CHOICECARE_IMAGE =
  "/id/NonMP/KY/Medicare/Home-KY-Medicare-ChoiceCare.png";
export const NV_MEDICARE_IMAGE = "/id/NonMP/NV/Medicare/Home-NV-Medicare.png";
export const NY_MEDICARE_IMAGE =
  "/id/NonMP/NY/Medicare/Home-NY-Medicare-SWH.png";
export const UT_MEDICARE_HAP_IMAGE =
  "/id/NonMP/UT/Medicare/Home-UT-Medicare-HAP.png";
export const UT_MEDICARE_COMMON_IMAGE =
  "/id/NonMP/UT/Medicare/Home-UT-Medicare-Common.png";
export const WI_MEDICARE_IMAGE = "/id/NonMP/WI/Medicare/Home-WI-Medicare.png";

export const MS_MEDICAID_MSCAN_IMAGE =
  "/id/NonMP/MS/Medicaid/Home-MS-Medicaid-CAN.png";
export const MS_MEDICAID_CHIP_IMAGE =
  "/id/NonMP/MS/Medicaid/Home-MS-Medicaid-CHIP.png";
export const FL_MEDICAID_SPECIALTY_PLAN_IMAGE =
  "/id/NonMP/FL/Medicaid/Home-FL-Medicaid-SP.png";
export const FL_MEDICAID_LONG_TERM_CARE_IMAGE =
  "/id/NonMP/FL/Medicaid/Home-FL-Medicaid-LTC.png";
export const FL_MEDICAID_MMA_IMAGE =
  "/id/NonMP/FL/Medicaid/Home-FL-Medicaid-MMA_N_MMALTC.png";
export const FL_MEDICAID_MMA_MMALTC_IMAGE =
  "/id/NonMP/FL/Medicaid/Home-FL-Medicaid-MMA_N_MMALTC.png";
export const FL_MEDICARE_IMAGE =
  "/id/NonMP/FL/Medicare/Home-FL-Medicare-NoCopay.png";
export const COMMON_MEDICARE_IMAGE =
  "/id/NonMP/NV/Medicare/Home-NV-Medicare.png";
export const TX_MEDICAID_CHIP_RSA_IMAGE =
  "/id/NonMP/TX/Medicaid/Home-TX-Medicaid-CHIP.png";
export const TX_MEDICAID_CPR_IMAGE =
  "/id/NonMP/TX/Medicaid/Home-TX-Medicaid-CHIP-PERINATE.png";
export const TX_MEDICAID_CHIP_PERINATE_IMAGE =
  "/id/NonMP/TX/Medicaid/Home-TX-Medicaid-CHIP-PERINATE.png";
export const TX_MEDICAID_STAR_IMAGE =
  "/id/NonMP/TX/Medicaid/Home-TX-Medicaid-STAR.png";
export const TX_MEDICAID_CHIP_IMAGE =
  "/id/NonMP/TX/Medicaid/Home-TX-Medicaid-CHIP.png";
export const TX_MEDICAID_STAR_PLUS_IMAGE =
  "/id/NonMP/TX/Medicaid/Home-TX-Medicaid-STAR-PLUS.png";
export const TX_DUAL_IMAGE = "/id/NonMP/TX/Dual/Home-TX-Dual.png";
export const TX_MEDICARE_IMAGE =
  "/id/NonMP/TX/Medicare/Home-TX-Medicare-NoCopay.png";
export const ID_MEDICAID_IMAGE = "/id/NonMP/ID/Medicaid/Home-ID-Medicaid.png";
export const ID_DUAL_IMAGE = "/id/NonMP/ID/Dual/Home-ID-Dual.png";
export const ID_MEDICARE_COAPY_IMAGE =
  "/id/NonMP/ID/Medicare/Home-ID-Medicare-Copay.png";
export const ID_MEDICARE_NOCOAPY_IMAGE =
  "/id/NonMP/ID/Medicare/Home-ID-Medicare-NoCopay.png";
export const MI_MEDICAID_IMAGE = "/id/NonMP/MI/Medicaid/Home-MI-Medicaid.png";
export const MI_DUAL_IMAGE = "/id/NonMP/MI/Dual/Home-MI-Dual.png";
export const MI_MEDICARE_IMAGE =
  "/id/NonMP/MI/Medicare/Home-MI-Medicare-NoCopay.png";
// Wave 3.1 Home-IDCard Image path
// SC
export const SC_MEDICAID_IMAGE = "/id/NonMP/SC/Medicaid/Home-SC-Medicaid.png";
export const SC_MEDICARE_IMAGE = "/id/NonMP/SC/Medicare/Home-SC-Medicare.png";
export const SC_DUAL_IMAGE = "/id/NonMP/SC/Dual/Home-SC-Dual.png";

// IL
export const IL_MEDICAID_IMAGE = "/id/NonMP/IL/Medicaid/Home-IL-Medicaid.png";
export const IL_MEDICAID_HBI_IMAGE =
  "/id/NonMP/IL/Medicaid/member-id-il-HIB-home.png";
export const IL_MEDICAID_MLTSS_IMAGE =
  "/id/NonMP/IL/Medicaid/Home-IL-Medicaid-MLTSS.png";
export const IL_MEDICARE_IMAGE = "/id/NonMP/SC/Medicare/Home-SC-Medicare.png";
export const IL_DUAL_IMAGE = "/id/NonMP/IL/Dual/Home-IL-Dual.png";

// VA
export const VA_MEDICAID_IMAGE = "/id/NonMP/VA/Medicaid/Home-VA-Medicaid.png";
export const VA_MEDICAID_FAMIS_IMAGE =
  "/id/NonMP/VA/Medicaid/Home-VA-Medicaid-FAMIS.png";
export const VA_MEDICARE_IMAGE = "/id/NonMP/SC/Medicare/Home-SC-Medicare.png";
export const FAMIS = "_FAMIS";
export const FAMIS_M4 = "FAMIS M4";

// MA
export const MA_MEDICAID_IMAGE = "/id/NonMP/MA/Medicaid/Home-MA-Medicaid.png";
export const MA_MEDICARE_IMAGE = "/id/NonMP/MA/Medicare/Home-MA-Medicare.png";

// WA
export const WA_MEDICAID_BHSO_IMAGE =
  "/id/NonMP/WA/Medicaid/Home-WA-Medicaid-BHSO.png";
export const WA_MEDICAID_COMMON_IMAGE =
  "/id/NonMP/WA/Medicaid/Home-WA-Medicaid-Common.png";
export const WA_MEDICARE_IMAGE = "/id/NonMP/SC/Medicare/Home-SC-Medicare.png";

// CA
export const CA_MEDICAID_IMAGE = "/id/NonMP/CA/Medicaid/Home-CA-Medicaid.png";
export const CA_MEDICARE_IMAGE = "/id/NonMP/CA/Medicare/Home-CA-Medicare.png";
export const CA_DUAL_IMAGE = "/id/NonMP/CA/Medicare/Home-CA-Medicare.png";

// OH
export const OH_MCAID_RISE_FULL_IMAGE =
  "/id/NonMP/OH/Medicaid/Home-OHRISE-Mcaid-Full.png";
export const OH_MCAID_RISE_PARTIAL_IMAGE =
  "/id/NonMP/OH/Medicaid/Home-OHRISE-Mcaid-Partial.png";
export const OH_MCAID_RISE_STANDARD_IMAGE =
  "/id/NonMP/OH/Medicaid/Home-OHRISE-Mcaid-Standard.png";
export const OH_MCAID_FULL_IMAGE = "/id/NonMP/OH/Medicaid/Home-Mcaid-Full.png";
export const OH_MCAID_PARTIAL_IMAGE =
  "/id/NonMP/OH/Medicaid/Home-Mcaid-Partial.png";
export const OH_MCAID_STANDARD_IMAGE =
  "/id/NonMP/OH/Medicaid/Home-Mcaid-Standard.png";
export const RISE = "_RISE";
export const FULL = "_FULL";
export const PARTIAL = "_PRTL";
export const STANDARD = "_STNRD";
export const CSP_FULL = "CSP_Full";
export const CSP_PARTIAL = "CSP_Partial";
export const OH_DUAL_IMAGE = "/id/NonMP/OH/Duals/Home-OH-Dual.png";
export const OH_MEDICARE_IMAGE = "/id/NonMP/SC/Medicare/Home-SC-Medicare.png";
//NE
export const NE_MEDICAID_IMAGE = "/id/NonMP/NE/Medicaid/ID_front_blank.png";
//NM
export const NM_MEDICAID_IMAGE = "/id/NonMP/NM/Medicaid/NM_front_blank.png";

export const MEDICAID_REDET_BANNER_STATES = [
  "az",
  "ut",
  "wa",
  "ny",
  "nv",
  "ky",
  "ca",
  "id",
  "fl",
  "il",
  "mi",
  "oh",
  "sc",
  "tx",
  "ms",
  "va",
  "ma",
];
export const NON_MP_DISCLAIMER_WAVE3_STATES = ["id", "fl", "mi", "tx", "ms"];
export const NON_MP_SA_DISCLAIMER_STATES_1 = [
  "mi",
  "tx",
  "oh",
  "va",
  "sc",
  "ca",
];
export const NON_MP_SA_DISCLAIMER_STATES_2 = ["id", "fl", "ms"];
export const UT_PROGRAMID_26 = "THPA0026";
export const TX_DUALS_49 = "QMXHPQ2249";
export const IA_MEDICAID_PLANID = "QMXBP8417";
export const IA_HWK_PLANID = "QMXBP8418";
export const IA_IHAWP_PLANID = "QMXBP8419";

export const CA_PROGID_LIST_HIDE_MYDOC = [
  "QMXHPQ2320",
  "TFCA23HP0000001",
  "QMXHPQ2233",
  "QMXHPQ2232",
  "QMXHPQ2234",
  "QMXHPQ2236",
  "QMXHPQ2239",
  "QMXHPQ2237",
  "QMXHPQ2238",
  "QMXHPQ2235",
];

export const NM_MCAID_PROGID = "QMXHPQ1110";
export const SSRS_REPORT_NAMES = [
  "H2060-A, Addendum to Form H2060",
  "H6516, Community First Choice Assessment",
  "H2060, Needs Assessment Questionnaire and Task/Hour Guide",
];
export const ASSESSMENT_PDF_NAMES = [
  "H2060-B, Needs Assessment Addendum",
  "TX Facility Nursing Assessment",
];
// Affinity status denoted by Affinity property
// 'N' for not affinity member
// 'Y' for affinity member
// 'B' both affinity and non affinity members
export const NY_FORMS_DOCUMENTS_LINK_ID = [
  {
    id: "MyResources-Form-NY-MP-AppointmentofRepresentativeForms",
    supportedPrograms: [{ programId: "PGM0000000027", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-HealthCareProxy",
    supportedPrograms: [{ programId: "PGM0000000027", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-StepUpGrid-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-OutofNetworkPharmacies-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-Transitionpolicy-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-OTCproductcatalog-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-MedicationSafety-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-ChangestotheFormulary-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-Formulary-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-MedicareQuickstartguide-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-ExerciseReimbursementForm",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "Y" },
      { programId: "QMXHPQ2284", Affinity: "Y" },
      { programId: "QMXHPQ2285", Affinity: "Y" },
      { programId: "QMXHPQ2286", Affinity: "Y" },
      { programId: "QMXREG010003", Affinity: "Y" },
    ],
  },
  {
    id: "MyResources-Form-NY-MP-ExerciseReimbursementFormNONAFFINITY",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "N" },
      { programId: "QMXHPQ2284", Affinity: "N" },
      { programId: "QMXHPQ2285", Affinity: "N" },
      { programId: "QMXHPQ2286", Affinity: "N" },
      { programId: "QMXREG010003", Affinity: "N" },
    ],
  },
  {
    id: "MyResources-Form-NY-MP-FraudandAbuse",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "N" },
      { programId: "QMXHPQ2284", Affinity: "N" },
      { programId: "QMXHPQ2285", Affinity: "N" },
      { programId: "QMXHPQ2286", Affinity: "N" },
      { programId: "QMXREG010003", Affinity: "N" },
      { programId: "QMT2HPQ0155", Affinity: "N" },
      { programId: "QMT2HPQ0166", Affinity: "N" },
      { programId: "PGM0000000015", Affinity: "N" },
    ],
  },
  {
    id: "MyResources-Form-NY-MP-DrugMailOrderForm",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "N" },
      { programId: "QMXHPQ2284", Affinity: "N" },
      { programId: "QMXHPQ2285", Affinity: "N" },
      { programId: "QMXHPQ2286", Affinity: "N" },
      { programId: "QMXREG010003", Affinity: "N" },
      { programId: "QMT2HPQ0155", Affinity: "N" },
      { programId: "QMT2HPQ0166", Affinity: "N" },
      { programId: "PGM0000000015", Affinity: "N" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-DisenrollmentRights",
    supportedPrograms: [{ programId: "PGM0000000027", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-BehavioralHealthFormulary",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "N" },
      { programId: "QMXHPQ2284", Affinity: "N" },
      { programId: "QMXHPQ2285", Affinity: "N" },
      { programId: "QMXHPQ2286", Affinity: "N" },
      { programId: "QMXREG010003", Affinity: "N" },
      { programId: "QMT2HPQ0155", Affinity: "N" },
      { programId: "QMT2HPQ0166", Affinity: "N" },
      { programId: "PGM0000000015", Affinity: "N" },
    ],
  },
  {
    id: "MyResources-Documents-NY-Medicaid-ProviderandPharmacyDirectory-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-PriorAuthorizationGrid-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-NY-Medicaid-ANOC-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-NY-Medicaid-SummaryOfBenefits-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-NY-Medicaid-EvidenceofCoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Documents-Gl-Cm-BenefitsataGlances",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
      { programId: "PGM0000000027", Affinity: "N" },
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-FormularyNY",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-MemberHandbooks",
    supportedPrograms: [
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000027", Affinity: "N" },
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-QuickStartGuideNY",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "N" },
      { programId: "QMT2HPQ0166", Affinity: "N" },
      { programId: "PGM0000000015", Affinity: "N" },
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-SubscriberAgreement",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
  {
    id: "MyResources-Form-NY-MP-Complaints",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
];
export const NY_HELPFUL_LINK_ID = [
  {
    id: "helpfullinks-home-ny-medicaid-sbc",
    supportedPrograms: [{ programId: "PGM0000000027", Affinity: "B" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-findaprovider-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-findadoctor",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-findapharmacy",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-mypharmacy",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-healthyrewards-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-hra-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-localresources-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-mypharmacy-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-priorauth-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-qsg-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-sbc-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-sbc-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-virtualcare-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-formulary",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-BenefitsataGlance",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
      { programId: "PGM0000000027", Affinity: "N" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-Dental",
    supportedPrograms: [{ programId: "PGM0000000027", Affinity: "N" }],
  },
  {
    id: "helpfullinks-home-ny-medicaid-memberhandbook",
    supportedPrograms: [
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000027", Affinity: "N" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-QuickStartGuide",
    supportedPrograms: [
      { programId: "PGM0000000015", Affinity: "N" },
      { programId: "QMT2HPQ0155", Affinity: "N" },
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "N" },
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-SubscriberAgreement",
    supportedPrograms: [
      { programId: "PGM0000000015", Affinity: "B" },
      { programId: "QMXHPQ2283", Affinity: "N" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
    ],
  },

  {
    id: "helpfullinks-ny-medicaid-findaprovider-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-localresources-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-healthyrewards-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-mypharmacy-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-sbc-mycoverage",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-virtualcare-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-hra-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-priorauth-mycoverage-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-sbc-mycoverage",
    supportedPrograms: [{ programId: "PGM0000000027", Affinity: "B" }],
  },
  {
    id: "helpfullinks-ny-medicaid-formulary-mycoverage",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-BenefitsataGlance-mycoverage",
    supportedPrograms: [
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000015", Affinity: "B" },
      { programId: "PGM0000000027", Affinity: "N" },
    ],
  },
  {
    id: " helpfullinks-ny-medicaid-mypharmacy-mycoverage",
    supportedPrograms: [
      { programId: "PGM0000000015", Affinity: "B" },
      { programId: "QMXHPQ2283", Affinity: "N" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-Dental-mycoverage",
    supportedPrograms: [{ programId: "PGM0000000027", Affinity: "N" }],
  },
  {
    id: "helpfullinks-ny-medicaid-memberhandbook-mycoverage",
    supportedPrograms: [
      { programId: "QMT2HPQ0155", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "B" },
      { programId: "PGM0000000027", Affinity: "N" },
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-QuickStartGuide-mycoverage",
    supportedPrograms: [
      { programId: "PGM0000000015", Affinity: "N" },
      { programId: "QMT2HPQ0155", Affinity: "N" },
      { programId: "QMXHPQ2283", Affinity: "B" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
      { programId: "QMT2HPQ0166", Affinity: "N" },
      { programId: "QMXHPQ2308", Affinity: "N" },
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-SubscriberAgreement-mycoverage",
    supportedPrograms: [
      { programId: "PGM0000000015", Affinity: "B" },
      { programId: "QMXHPQ2283", Affinity: "N" },
      { programId: "QMXHPQ2284", Affinity: "B" },
      { programId: "QMXHPQ2285", Affinity: "B" },
      { programId: "QMXHPQ2286", Affinity: "B" },
      { programId: "QMXREG010003", Affinity: "B" },
    ],
  },
];
export const HELP_FULLINK_ID = [
  {
    id: "helpfullinks-home-va-medicaid-memberhandbook-CCC",
    programIds: ["QMXHPQ2316"],
    planIds: ["QMXBP8462", "QMXBP8463"],
  },
  {
    id: "helpfullinks-home-va-medicaid-memberhandbook-Medallion",
    programIds: ["QMXHPQ2316"],
    planIds: ["QMXBP8460", "QMXBP8461"],
  },
  {
    id: "helpfullinks-mycoverage-va-medicaid-memberhandbook-CCC",
    programIds: ["QMXHPQ2316"],
    planIds: ["QMXBP8462", "QMXBP8463"],
  },
  {
    id: "helpfullinks-mycoverage-va-medicaid-memberhandbook-Medallion",
    programIds: ["QMXHPQ2316"],
    planIds: ["QMXBP8460", "QMXBP8461"],
  },

  {
    id: "helpfullinks-home-wa-medicaid-QuickStartGuide",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "helpfullinks-home-wa-medicaid-virtualcare",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "helpfullinks-home-wa-medicaid-formulary",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "helpfullinks-home-wa-medicaid-Vision",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "helpfullinks-mycoverage-wa-medicaid-QuickStartGuide",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "helpfullinks-mycoverage-wa-medicaid-virtualcare",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "helpfullinks-mycoverage-wa-medicaid-formulary",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "helpfullinks-mycoverage-wa-medicaid-Vision",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "MyResources-Form-TX-MP-Appeals",
    programIds: [
      "QMXHPQ0879",
      "QMXHPQ0882",
      "QMXHPQ2119",
      "QMXHPQ2141",
      "TQPROG1815",
      "QMXHPQ2268",
    ],
  },
  {
    id: "MyResources-Form-IL-MP-RequestToChangePrimaryCareProviderForm",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "MyResources-Documents-CA-ProviderDirectory",
    programIds: [
      "QMXHPQ2227",
      "QMXHPQ2232",
      "QMXHPQ2228",
      "QMXHPQ2126",
      "QMXHPQ2119",
      "QMXHPQ2236",
      "HNQMXHPQ2236",
      "QMXHPQ2234",
      "TQPROG1900",
      "TQPROG1901",
      "QMXHPQ2125",
      "QMXHPQ2117",
      "QMXHPQ2238",
      "QMXHPQ2237",
      "TQPROG1904",
      "TQPROG1905",
      "QMXHPQ2120",
      "QMXHPQ2239",
      "TQPROG1903",
      "QMXHPQ2129",
      "QMXHPQ2235",
      "TQPROG1906",
    ],
  },
  {
    id: "MyResources-Documents-WA-Formulary",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "MyResources-Documents-WA-QuickStartGuide",
    programIds: [
      "QMXHPQ2269",
      "QMXHPQ2270",
      "QMXHPQ2271",
      "QMXHPQ2272",
      "QMXREG030001",
    ],
  },
  {
    id: "MyResources-Documents-IL-Formulary",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "MyResources-Documents-TX-Medicaid-SummmaryofBenefits",
    programIds: ["QMXHPQ2268"],
  },
  {
    id: "MyResources-Documents-FL-Medicaid-BenefitsataGlances",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "MyResources-Documents-FL-Medicaid-SummmaryofBenefits",
    programIds: ["QMXHPQ2259"],
  },
  {
    id: "MyResources-Documents-FL-Medicaid-QuickStartGuideFL",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "MyResources-Documents-FL-Medicaid-MemberHandbook",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-home-fl-medicaid-BenefitsataGlance",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-home-fl-medicaid-BenefitsataGlance",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },

  {
    id: "helpfullinks-home-fl-medicaid-formulary",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-home-fl-medicaid-memberhandbook",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-home-fl-medicaid-QuickStartGuide",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-mycoverage-fl-medicaid-BenefitsataGlance",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-mycoverage-fl-medicaid-formulary",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-mycoverage-fl-medicaid-memberhandbook",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-mycoverage-fl-medicaid-QuickStartGuide",
    programIds: [
      "PGM0000000040",
      "QMXHPQ1268",
      "QMXHPQ2224",
      "QMXHPQ2244",
      "QMXHPQ2259",
    ],
  },
  {
    id: "helpfullinks-home-il-medicaid-visionbenefits",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "helpfullinks-home-il-medicaid-mypharmacy",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "helpfullinks-home-il-medicaid-formulary",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "helpfullinks-home-il-medicaid-requestproviderdirector",
    programIds: ["QMXHPQ0851"],
  },
  {
    id: "helpfullinks-home-tx-medicaid-memberhandbook",
    programIds: [
      "QMXHPQ0879",
      "QMXHPQ0882",
      "QMXHPQ0881",
      "TQPROG1814",
      "QMXHPQ2141",
      "TQPROG1815",
    ],
  },
  {
    id: "helpfullinks-home-tx-medicaid-BenefitsataGlance",
    programIds: [
      "QMXHPQ0879",
      "QMXHPQ0882",
      "QMXHPQ0881",
      "TQPROG1814",
      "QMXHPQ2141",
      "TQPROG1815",
    ],
  },
  {
    id: "helpfullinks-home-tx-medicaid-Transportation",
    programIds: ["QMXHPQ0879", "QMXHPQ0882"],
  },
  {
    id: "helpfullinks-home-tx-medicaid-QuickStartGuide",
    programIds: [
      "QMXHPQ0879",
      "QMXHPQ0882",
      "QMXHPQ0881",
      "TQPROG1814",
      "QMXHPQ2141",
      "TQPROG1815",
    ],
  },
  {
    id: "helpfullinks-tx-medicaid-memberhandbook-mycoverage",
    programIds: [
      "QMXHPQ0879",
      "QMXHPQ0882",
      "QMXHPQ0881",
      "TQPROG1814",
      "QMXHPQ2141",
      "TQPROG1815",
    ],
  },
  {
    id: "helpfullinks-tx-medicaid-BenefitsataGlance-mycoverage",
    programIds: [
      "QMXHPQ0879",
      "QMXHPQ0882",
      "QMXHPQ0881",
      "TQPROG1814",
      "QMXHPQ2141",
      "TQPROG1815",
    ],
  },
  {
    id: "helpfullinks-tx-medicaid-Transportation-mycoverage",
    programIds: ["QMXHPQ0879", "QMXHPQ0882"],
  },
  {
    id: "helpfullinks-tx-medicaid-QuickStartGuide-mycoverage",
    programIds: [
      "QMXHPQ0879",
      "QMXHPQ0882",
      "QMXHPQ0881",
      "TQPROG1814",
      "QMXHPQ2141",
      "TQPROG1815",
    ],
  },
  {
    id: "helpfullinks-mycoverage-il-medicaid-visionbenefits",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "helpfullinks-mycoverage-il-medicaid-mypharmacy",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "helpfullinks-mycoverage-il-medicaid-formulary",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "helpfullinks-mycoverage-il-medicaid-requestproviderdirector",
    programIds: ["QMXHPQ0851"],
  },
  {
    id: "helpfullinks-ms-medicaid-virtualcare-mycoverage",
    programIds: ["QMXHPQ2287"],
  },
  {
    id: "helpfullinks-home-ms-medicaid-virtualcare",
    programIds: ["QMXHPQ2287"],
  },
  // {
  //   id: "helpfullinks-home-ca-medicaid-BenefitsAtAGlance",
  //   programIds: [
  //     "QMXHPQ2117",
  //     "QMXHPQ2118",
  //     "QMXHPQ2119",
  //     "QMXHPQ2120",
  //     "QMXHPQ2129",
  //     "QMXHPQ2227",
  //     "QMXHPQ2228",
  //     "QMXHPQ2126",
  //     "QMXHPQ2236",
  //     "QMXHPQ2234",
  //     "TQPROG1900",
  //     "QMXHPQ2239",
  //     "TQPROG1903",
  //     "QMXHPQ2125",
  //     "QMXHPQ2238",
  //     "QMXHPQ2237",
  //     "TQPROG1904",
  //     "TQPROG1905",
  //     "QMXHPQ2235",
  //     "TQPROG1906",
  //   ],
  // },
  // {
  //   id: "helpfullinks-home-ca-medicaid-handbook",
  //   programIds: [
  //     "QMXHPQ2117",
  //     "QMXHPQ2118",
  //     "QMXHPQ2119",
  //     "QMXHPQ2120",
  //     "QMXHPQ2129",
  //     "QMXHPQ2227",
  //     "QMXHPQ2228",
  //     "QMXHPQ2126",
  //     "QMXHPQ2236",
  //     "QMXHPQ2234",
  //     "TQPROG1900",
  //     "QMXHPQ2239",
  //     "TQPROG1903",
  //     "QMXHPQ2125",
  //     "QMXHPQ2238",
  //     "QMXHPQ2237",
  //     "TQPROG1904",
  //     "TQPROG1905",
  //     "QMXHPQ2235",
  //     "TQPROG1906",
  //   ],
  // },
  {
    id: "helpfullinks-home-fl-medicaid-ltc-handbook",
    programIds: ["QMXHPQ2224"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-ltc-BenefitsAtAGlance",
    programIds: ["QMXHPQ2224"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-ltc-quickStartGuide",
    programIds: ["QMXHPQ2224"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-mma-BenefitsAtAGlance",
    programIds: ["QMXHPQ1268"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-mma-handbook",
    programIds: ["QMXHPQ1268"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-mma-quickStartGuide",
    programIds: ["QMXHPQ1268"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-mma+ltc-BenefitsAtAGlance",
    programIds: ["QMXHPQ2244"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-mma+ltc-handbook",
    programIds: ["QMXHPQ2244"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-mma+ltc-quickStartGuide",
    programIds: ["QMXHPQ2244"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-sp-BenefitsAtAGlance",
    programIds: ["PGM0000000040"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-sp-handbook",
    programIds: ["PGM0000000040"],
  },
  {
    id: "helpfullinks-home-fl-medicaid-sp-quickStartGuide",
    programIds: ["PGM0000000040"],
  },
  // {
  //   id: "helpfullinks-ca-medicaid-BenefitsAtAGlance-mycoverage",
  //   programIds: [
  //     "QMXHPQ2117",
  //     "QMXHPQ2118",
  //     "QMXHPQ2119",
  //     "QMXHPQ2120",
  //     "QMXHPQ2129",
  //     "QMXHPQ2227",
  //     "QMXHPQ2228",
  //     "QMXHPQ2126",
  //     "QMXHPQ2236",
  //     "QMXHPQ2234",
  //     "TQPROG1900",
  //     "QMXHPQ2239",
  //     "TQPROG1903",
  //     "QMXHPQ2125",
  //     "QMXHPQ2238",
  //     "QMXHPQ2237",
  //     "TQPROG1904",
  //     "TQPROG1905",
  //     "QMXHPQ2235",
  //     "TQPROG1906",
  //   ],
  // },
  // {
  //   id: "helpfullinks-ca-medicaid-handbook-mycoverage",
  //   programIds: [
  //     "QMXHPQ2117",
  //     "QMXHPQ2118",
  //     "QMXHPQ2119",
  //     "QMXHPQ2120",
  //     "QMXHPQ2129",
  //     "QMXHPQ2227",
  //     "QMXHPQ2228",
  //     "QMXHPQ2126",
  //     "QMXHPQ2236",
  //     "QMXHPQ2234",
  //     "TQPROG1900",
  //     "QMXHPQ2239",
  //     "TQPROG1903",
  //     "QMXHPQ2125",
  //     "QMXHPQ2238",
  //     "QMXHPQ2237",
  //     "TQPROG1904",
  //     "TQPROG1905",
  //     "QMXHPQ2235",
  //     "TQPROG1906",
  //   ],
  // },
  {
    id: "helpfullinks-fl-medicaid-ltc-BenefitsAtAGlance-mycoverage",
    programIds: ["QMXHPQ2224"],
  },
  {
    id: "helpfullinks-fl-medicaid-ltc-handbook-mycoverage",
    programIds: ["QMXHPQ2224"],
  },
  {
    id: "helpfullinks-fl-medicaid-ltc-quickStartGuide-mycoverage",
    programIds: ["QMXHPQ2224"],
  },
  {
    id: "helpfullinks-fl-medicaid-mma-BenefitsAtAGlance-mycoverage",
    programIds: ["QMXHPQ1268"],
  },
  {
    id: "helpfullinks-fl-medicaid-mma-handbook-mycoverage",
    programIds: ["QMXHPQ1268"],
  },
  {
    id: "helpfullinks-fl-medicaid-mma-quickStartGuide-mycoverage",
    programIds: ["QMXHPQ1268"],
  },
  {
    id: "helpfullinks-fl-medicaid-mma+ltc-BenefitsAtAGlance-mycoverage",
    programIds: ["QMXHPQ2244"],
  },
  {
    id: "helpfullinks-fl-medicaid-mma+ltc-handbook-mycoverage",
    programIds: ["QMXHPQ2244"],
  },
  {
    id: "helpfullinks-fl-medicaid-mma+ltc-quickStartGuide-mycoverage",
    programIds: ["QMXHPQ2244"],
  },
  {
    id: "helpfullinks-fl-medicaid-sp-BenefitsAtAGlance-mycoverage",
    programIds: ["PGM0000000040"],
  },
  {
    id: "helpfullinks-fl-medicaid-sp-quickStartGuide-mycoverage",
    programIds: ["PGM0000000040"],
  },
  {
    id: "helpfullinks-fl-medicaid-sp-handbook-mycoverage",
    programIds: ["PGM0000000040"],
  },
  {
    id: "helpfullinks-home-ut-medicaid-memberhandbook",
    programIds: ["THPA0026", "THPA0028"],
  },
  {
    id: "MyResources-Documents-MI-Medicare-ANOC",
    programIds: ["QMXHPQ1683", "QMXHPQ1682"],
    planIds: ["QMXBP7600", "QMXBP8389", "QMXBP8422", "QMXBP8412"],
  },
  {
    id: "helpfullinks-home-ut-medicaid-formulary",
    programIds: ["THPA0026", "THPA0028"],
    planIds: [
      "QMXBP7576",
      "QMXBP7577",
      "TBPA0056",
      "TBPA0059",
      "TBPA0060",
      "QMXBP7575",
      "TBPA0055",
      "TBPA0070",
      "TBPA0071",
      "TBPA0072",
      "TBPA0073",
      "TBPA0074",
      "TBPA0075",
      "TBPA0076",
      "TBPA0077",
    ],
  },
  {
    id: "helpfullinks-ut-medicaid-memberhandbook-mycoverage",
    programIds: ["THPA0026", "THPA0028"],
  },
  {
    id: "helpfullinks-ut-medicaid-formulary-mycoverage",
    programIds: ["THPA0026", "THPA0028"],
    planIds: [
      "QMXBP7576",
      "QMXBP7577",
      "TBPA0056",
      "TBPA0059",
      "TBPA0060",
      "QMXBP7575",
      "TBPA0055",
      "TBPA0070",
      "TBPA0071",
      "TBPA0072",
      "TBPA0073",
      "TBPA0074",
      "TBPA0075",
      "TBPA0076",
      "TBPA0077",
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-BenefitsataGlances",
    programIds: [
      "PGM0000000027",
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-DisenrollmentRights",
    programIds: ["PGM0000000027"],
  },
  {
    id: "helpfullinks-home-ny-medicaid-MemberHandbooks",
    programIds: ["PGM0000000027"],
  },
  {
    id: "MyResources-Documents-Gl-Cm-BehavioralHealthFormulary",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-FormularyNY",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-QuickStartGuideNY",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-Documents-Gl-Cm-SubscriberAgreement",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-VirtualCare",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-VisionBenefits",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-Cm-MyPharmacy",
    programIds: [
      "PGM0000000015",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-virtualcare",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "helpfullinks-home-ny-medicaid-Vision",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-NYStateDepartmentofHealth",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-NYStateOfficeforPeoplewithDevelopmentalDisabilities",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-NYStateOfficeofAlcoholismandSubstanceAbuseServices",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-NYStateOfficeofChildrenandFamilyServices",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-NYStateOfficeofMentalHealth",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-SBC",
    programIds: ["PGM0000000027"],
  },

  {
    id: "helpfullinks-ny-medicaid-Vision-mycoverage",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-virtualcare-mycoverage",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-Complaints-mycoverage",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-ChangeDoctor",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-Claims",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-CommunicationPreferences",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-FindADoctor",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-FindAPharmacy",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-ny-MD-MolinaHelpFinder",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-findadoctor-mycoverage",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-findapharmacy-mycoverage",
    programIds: [
      "PGM0000000015",
      "QMT2HPQ0155",
      "QMT2HPQ0166",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "helpfullinks-ny-medicaid-mypharmacy-mycoverage",
    programIds: [
      "PGM0000000015",
      "QMXHPQ2283",
      "QMXHPQ2284",
      "QMXHPQ2285",
      "QMXHPQ2286",
      "QMXREG010003",
      "PGM0000000027",
    ],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-CareConnection-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-Caregiving-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-FAQ-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-FindMyMedicines-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-FindMyProvider-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-HelpFinder-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-HRA-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-LocalResources-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-MolinaHealthcareYouTube-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-MyClaims-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-MyHealthyRewards-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-PriorAuthorizations-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-SOB-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-HelpfulLinks-Gl-Cm-VirtualCareTelehealth-DSNP",
    programIds: ["QMXHPQ2308"],
  },
  {
    id: "MyResources-Form-MA-Medicare-AdvancedDirectivesInformationSheet",
    programIds: ["QMXHPQ1683", "QMXHPQ1682"],
    planIds: ["QMXBP7600", "QMXBP8389", "QMXBP8422", "QMXBP8412"],
  },
  {
    id: "MyResources-Form-MA-Medicare-AdvancedDirectivesInformationSheet",
    programIds: ["QMXHPQ2310", "QMXHPQ2314"],
    planIds: ["QMXBP8440", "QMXBP8441", "QMXBP8440"],
  },
  {
    id: "MyResources-Documents-MA-Medicare-ANOC",
    programIds: ["QMXHPQ2310", "QMXHPQ2314"],
    planIds: ["QMXBP8440", "QMXBP8448", "QMXBP8441"],
  },
  {
    id: "MyResources-Documents-MA-Medicare-SummaryOfBenefitsAddendum",
    programIds: ["QMXHPQ2310", "QMXHPQ2314"],
    planIds: ["QMXBP8440", "QMXBP8448", "QMXBP8441"],
  },
  {
    id: "MyResources-Documents-MA-Medicare-EvidenceofCoverageAddendum",
    programIds: ["QMXHPQ2310", "QMXHPQ2314"],
    planIds: ["QMXBP8440", "QMXBP8441"],
  },
  {
    id: "MyResources-Documents-MA-Medicare-OTCList",
    programIds: ["QMXHPQ2310", "QMXHPQ2314"],
    planIds: ["QMXBP8440", "QMXBP8441"],
  },
  {
    id: "MyResources-Form-NY-MP-AppointmentofRepresentativeForm-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-CoverageDeterminationRequestForm-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-HIPPA-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-HowtoRequestaRedetermination-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-MailOrderForm-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-RedeterminationForm-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-Form-NY-MP-PharmacyDirectMemberReimbursementForm-DSNP",
    supportedPrograms: [{ programId: "QMXHPQ2308", Affinity: "N" }],
  },
  {
    id: "MyResources-HelpfulLinks-TX-MD-Transportation",
    programIds: ["QMXHPQ0879", "QMXHPQ0882", "QMXHPQ2268"],
  },
  {
    id: "MyResources-HelpfulLinks-MS-MD-VirtualCare",
    programIds: ["QMXHPQ2287"],
  },
  {
    id: "MyResources-HelpfulLinks-IL-MD-MyPharmacy",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "MyResources-HelpfulLinks-IL-MD-VisionBenefits",
    programIds: ["PGM0000000015", "QMXHPQ0855"],
  },
  {
    id: "MyResources-HelpfulLinks-IL-Cm-PPD",
    programIds: ["QMXHPQ0851"],
  },
  {
    id: "helpfullinks-home-oh-medicare-sbc",
    programIds: ["QMXHPQ1683", "QMXHPQ1682"],
    planIds: ["QMXBP8390", "QMXBP8415", "TFZ22BP00000007", "QMXBP8456"],
  },
  {
    id: "helpfullinks-home-oh-medicare-qsg",
    programIds: ["QMXHPQ1683", "QMXHPQ1682"],
    planIds: ["QMXBP8390", "QMXBP8415", "TFZ22BP00000007", "QMXBP8456"],
  },
  {
    id: "MyResources-HelpfulLinks-OH-medicare-SOB",
    programIds: ["QMXHPQ1683", "QMXHPQ1682"],
    planIds: ["QMXBP8390", "QMXBP8415", "TFZ22BP00000007", "QMXBP8456"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-Medicaid-Complaints",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-FindADoctor",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-FindAPharmacy",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-Formulary",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-HIPAA",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-MolinaHelpFinder",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-PsychHub",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-VirtualCare",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "MyResources-HelpfulLinks-WA-MD-Vision",
    programIds: ["QMXHPQ2269", "QMXHPQ2270", "QMXHPQ2271", "QMXHPQ2272"],
  },
  {
    id: "faq-ca-Medicaid-changeMyDoctor",
    programIds: [
      "QMXHPQ2320",
      "TFCA23HP0000001",
      "QMXHPQ2233",
      "QMXHPQ2234",
      "QMXHPQ2235",
      "QMXHPQ2237",
      "QMXHPQ2232",
      "QMXHPQ2236",
      "QMXHPQ2239",
      "QMXHPQ2238",
    ],
  },
  {
    id: "faq-il-Medicaid-changeMyDoctor",
    programIds: ["QMXHPQ0851"],
  },
  {
    id: "MyResources-Documents-CA-MemberHandbook",
    programIds: ["QMXHPQ2293", "QMXHPQ2292"],
    planIds: [
      "TFU223BP0000007",
      "TFU223BP0000008",
      "TFU223BP0000009",
      "TFU223BP0000010",
      "TFU223BP0000012",
      "TFU223BP0000013",
      "TFU223BP0000014",
      "QMXBP8439",
      "QMXBP8442",
      "QMXBP8444",
      "TFU223BP0000001",
      "TFU223BP0000002",
      "TFU223BP0000003",
      "TFU223BP0000004",
      "TFU223BP0000005",
      "TFU223BP0000011",
    ],
  },
];

export const ANNUAL_REWARD_LIST_COUNT = 10;
export const CDP_CHANNEL = "WEB";
export const ASSESSMENT_ENGLISH_LANG_CODE = "EN";
export const ASSESSMENT_SPANISH_LANG_CODE = "ES";
